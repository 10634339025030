import React from "react"

const LogoSm = () => {
  return (
    <svg
      id="logo-sm"
      className="logo-cris"
      width="49"
      height="32"
      viewBox="0 0 49 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="logo-cb"
        d="M43.9978 2.57312L43.3983 3.36774L43.9978 2.57312C42.6447 1.55232 40.9981 1 39.3052 1H36.0201C33.1782 1 30.5628 2.54975 29.1855 5.04354L18.2297 24.8795C17.6032 26.0138 16.4166 26.7143 15.1322 26.7143H14.4982C9.40843 26.7143 5.2724 22.5624 5.2724 17.4286V14.5714C5.2724 9.43755 9.40843 5.28571 14.4982 5.28571H23.1559C24.2605 5.28571 25.1559 4.39028 25.1559 3.28571V3C25.1559 1.89543 24.2605 1 23.1559 1H14.4982C7.03785 1 1 7.08165 1 14.5714V17.4286C1 24.9184 7.03785 31 14.4982 31H15.1322C17.9741 31 20.5895 29.4503 21.9668 26.9565L32.9226 7.12054C33.5492 5.98616 34.7357 5.28571 36.0201 5.28571H39.3052C40.0708 5.28571 40.8163 5.53543 41.4299 5.99831L41.6988 6.20121C43.381 7.47025 43.6052 9.9283 42.177 11.4846L40.4006 13.4203C39.1748 14.756 39.2607 16.8377 40.5938 18.0669L42.5759 19.8945C43.9733 21.1829 44.1188 23.3515 42.9041 24.8177L42.3956 25.4314C41.7214 26.2453 40.7241 26.7143 39.6743 26.7143H27.9964C26.8918 26.7143 25.9964 27.6097 25.9964 28.7143V29C25.9964 30.1046 26.8918 31 27.9964 31H39.6743C41.9965 31 44.197 29.9624 45.6814 28.1707L46.1899 27.557C48.8589 24.3354 48.5417 19.5741 45.4671 16.7392L44.2185 15.5879L45.3203 14.3872C48.4584 10.9677 47.9703 5.56996 44.2668 2.77601L43.9978 2.57312Z"
        stroke="white"
        strokeWidth="2"
      />
    </svg>
  )
}

export default LogoSm
